import React, { createContext, ReactNode, useCallback, useContext } from "react";
import { Config } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import { get, set } from "../storeUtils";
import { useStoredItem } from "./useStoredItem";

export interface IFileProvider {
    getImageUrl: (imageId: string|undefined) => Promise<string | undefined>
}

export const FileContext = createContext<IFileProvider>({} as IFileProvider);

type ProviderProps = {
    children: ReactNode
}

export type File = { id: string, url: string, date: Date };

export const FileProvider = ({ children }: ProviderProps) => {
    const apiUrl = `${Config.API_URL}/images`;
    const { getAccessTokenSilently } = useAuth0();

    const getImageUrl = useCallback(async (imageId: string|undefined) => {
        if (!imageId) return;

        const cached = get<File>(`image.${imageId}`);
        if (cached && new Date(cached.date) > new Date(Date.now() - (60_000 * 60))) {
            return cached.url;
        }

        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: Config.API_AUDIENCE
            }
        });

        const response = await fetch(`${apiUrl}/${imageId}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        if (!response.ok) {
            const err = await response.json();
            console.error("Http error", err);
            throw new Error(err);
        }

        const url = await response.text();

        const file: File = {
            id: imageId,
            url: url,
            date: new Date()
        }

        console.log('setting new file store data')
        set(`image.${imageId}`, file);

        return file.url;
    }, [getAccessTokenSilently]);

    return (
        <FileContext.Provider value={{ getImageUrl }}>
            {children}
        </FileContext.Provider>
    )
}

export const useFileProvider = () => {
    return useContext(FileContext);
}

export const useImageUrl = (imageId: string|undefined) => {
    const { getImageUrl } = useFileProvider();

    getImageUrl(imageId);

    const storedFile = useStoredItem<File>(`image.${imageId}`);
    return storedFile?.url;
}