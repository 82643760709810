
import { useAuth0 } from "@auth0/auth0-react";
import * as signalR from "@microsoft/signalr";
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { Config } from "../App";
import { SyncEvent } from "../models/sync-event";
import { Post } from "../models/post";
import { Notification } from "../models/notification";
import { set } from "../storeUtils";

export interface ISyncContext {}
type ProviderProps = {
    children: ReactNode
}

export const SyncContext = createContext<ISyncContext>({});

export const SyncProvider = ({children}:ProviderProps) => {
    let connection: signalR.HubConnection | undefined = undefined;
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();

    useEffect(()=>{
        const buildConnection = async () =>{
            if (!isAuthenticated) return;
            if (connection) await connection.stop();

            const token = getAccessTokenSilently({
                authorizationParams: {
                    audience: Config.API_AUDIENCE
                }
            });
    
            connection = new signalR.HubConnectionBuilder()
                    .configureLogging(signalR.LogLevel.Information)
                    .withAutomaticReconnect()
                    .withUrl(`${Config.API_URL}/sync`, { accessTokenFactory: () => token })
                    .build();
            connection.on('set', (syncEvent: SyncEvent) => persistSyncEvent(syncEvent));
            connection.on('delete', (syncEvent: SyncEvent) => persistSyncEvent(syncEvent));
            await connection.start();
            console.log('Connected to Sync Endpoint')
        };

        buildConnection();

    }, [isAuthenticated]);
    return (
        <SyncContext.Provider value={{}}>
            {children}
        </SyncContext.Provider>
    )
}

export const persistSyncEvent = (syncEvent: SyncEvent) => {
    const storageId = getStorageId(syncEvent);
    if (syncEvent.action === 'set') {
        set(storageId, syncEvent.entity);
        console.debug(`SyncEvent: SET - ${storageId}`);
    }
    else {
        localStorage.delete(storageId);
        console.debug(`SyncEvent: DELETE - ${storageId}`);
    }
};

const getStorageId = (syncEvent: SyncEvent): string => {
    switch (syncEvent.entityName) {
        case ('user'): {
            return `user.${syncEvent.id}`;
        }
        case ('post'): {
            const post = syncEvent.entity as Post;
            return `post.${post.timelineId}.${post.id}.${new Date(post.dateCreated!).getTime()}`
        }
        case ('activeconnection'): {
            const activeConn = syncEvent.entity as { userId: string };
            return `activeconnection.${activeConn.userId}`
        }
        case ('notification'): {
            const notification = syncEvent.entity as Notification;
            return `notification.${notification.timelineId}.${notification.id}`
        }
        case ('partnership'): {
            return `partnership.${syncEvent.id}`;
        }
        default: {
            throw new Error(`Could not determine storageId for ${syncEvent.entityName}`);

        }
    }
}