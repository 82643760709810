import React from "react"
import { useImageUrl } from "../providers/FileProvider"
export const Avatar = (props: { imageId: string | undefined, size: number, alt?: string }) => {
    const url = useImageUrl(props.imageId);
    return (
        <div style={{height: props.size, width: props.size}} className={`rounded-full justify-center overflow-hidden bg-blue`}>
            {url ? <img className="object-center" width={props.size} src={url} alt={props.alt} /> : null}
            {!url ? <div style={{height: props.size, width: props.size}} className="font-bold text-white flex justify-center items-center">{props.alt}</div> : null}
        </div>
    )
}