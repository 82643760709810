import { useNavigate } from "react-router-dom";
import { ClientList } from "../components/ClientList"
import { UpcomingAppointmentList } from "../components/UpcomingAppointmentList"
import { withAuth } from './withAuth';

export const HomeScreen = withAuth(() => {
    const navigate = useNavigate();
    return (
        <div className='flex flex-col sm:flex-row gap-5'>
            <div className="sm:w-1/2">
                <ClientList onClientClick={(clientId) => navigate(`clients/${clientId}`)} />
            </div>
            <div className="sm:w-1/2">
                <UpcomingAppointmentList />
            </div>
        </div>
    )
});