import { FaPencil, FaUserSecret } from "react-icons/fa6";
import { Card } from "./Card";
import { colors, initials } from "../styleUtils";
import { useUser } from "../providers/UserProvider";
import { CardHeader } from "./CardHeader";
import { get } from "../storeUtils";
import { User } from "../models/user";
import { Avatar } from "./Avatar";
import { Button } from "./Button";
import { RemainingSessionsIndicator } from './RemainingSessionsIndicator';
import { UpcomingAppointmentList } from "./UpcomingAppointmentList";
export type ClientDetailProps = {
    clientUserId?: string;
}

export const ClientDetail = (props: ClientDetailProps) => {
    const user = useUser(props.clientUserId);

    return (
        <div className="flex-1 flex flex-col gap-5">
            <div className="flex-1">
                <Card>
                    <div className="flex flex-1 gap-4 items-center justify-between">
                        <div className="flex gap-4">
                            <Avatar size={60} imageId={user?.profilePhotoId} alt={initials(user)}/>
                            <div className="flex flex-col justify-center">
                                <CardHeader text={`${user?.firstName} ${user?.lastName}`}/>
                                <p>{user?.emailAddress}</p>
                            </div>
                        </div>
                        <RemainingSessionsIndicator></RemainingSessionsIndicator>
                    </div>
                </Card>
            </div>
            <div className="flex-1 flex gap-5">
                <div className="flex w-1/2">
                    <UpcomingAppointmentList/>
                </div>
                <div className="flex w-1/2">
                    <Card>
                        <CardHeader text="Progress"/>
                    </Card>
                </div>
            </div>
            <div className="flex-1 flex gap-5">
                <div className="flex w-1/2">
                    <Card>
                        <CardHeader text="Notes"/>
                    </Card>
                </div>
                <div className="flex w-1/2">
                    <Card>
                        <CardHeader text="Shared Documents"/>
                    </Card>
                </div>
            </div>
        </div>
        
    )
}