import React from 'react';
import { HeaderBar } from './components/HeaderBar';
import { createBrowserRouter, RouterProvider, useLocation } from 'react-router-dom';
import { HomeScreen } from './screens/HomeScreen';
import { ClientsScreen } from './screens/ClientsScreen';
import { AddClientScreen } from './screens/AddClientScreen';
import { LoginScreen } from './screens/LoginScreen';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { LoggedInUserProvider, useLoggedInUser } from './providers/LoggedInUserProvider';
import { PartnershipProvider } from './providers/PartnershipProvider';
import { UserProvider } from './providers/UserProvider';
import { FileProvider } from './providers/FileProvider';
import { SyncProvider } from './providers/SyncProvider';
export const Config = {
  // DEV
  // API_URL: 'http://localhost:5115',
  // URL: 'http://localhost:3000',
  // // API_URL: 'https://mywellchat-dev.azurewebsites.net',
  // AUTH_0_CLIENT: 'cePIfEfRYCBRY7n5ydm0z5XkEkdn6Kvx',
  // AUTH_0_DOMAIN: 'dev-rd-and-me.us.auth0.com',
  // API_AUDIENCE: 'rd-and-me-server'

  // PROD
  URL: 'https://mywellchat.com',
  AUTH_0_CLIENT: "Nu7JLARdIS8MAFv3kBbb89RQUNUFAAXl",
  AUTH_0_DOMAIN: 'rd-and-me.us.auth0.com',
  API_URL: 'https://mywellchat.azurewebsites.net',
  API_AUDIENCE: 'rd-and-me-server'

}

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomeScreen />
  },
  {
    path: '/home',
    element: <HomeScreen />
  },
  {
    path: '/login',
    element: <LoginScreen />
  },
  {
    path: '/clients',
    element: <ClientsScreen />
  },
  {
    path: '/clients/*',
    element: <ClientsScreen />
  },
  {
    path: '/add-client',
    element: <AddClientScreen />
  }
])

function App() {
  return (
    <>
      <Auth0Provider
        domain={Config.AUTH_0_DOMAIN}
        clientId={Config.AUTH_0_CLIENT}
        cacheLocation='localstorage'
        authorizationParams={{
          redirect_uri: window.location.origin,
        }}>
        <LoggedInUserProvider>
          <SyncProvider>
            <UserProvider>
              <FileProvider>
                <PartnershipProvider>
                  <Root />
                </PartnershipProvider>
              </FileProvider>
            </UserProvider>
          </SyncProvider>
        </LoggedInUserProvider>
      </Auth0Provider>
    </>
  );
}

export default App;

const Root = () => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <div className='p-5 flex flex-col gap-5'>
        {isAuthenticated ? <HeaderBar /> : null}
        <div className='h-12' />
        <RouterProvider router={router} />
      </div>
    </>
  )
}