import { useEffect, useState } from "react";
import { get } from "../storeUtils";
import {isEqual} from 'lodash';
/**
 * Watches for updates to an item in local storage
 * @param key 
 * @returns 
 */
export function useStoredItem<T>(key: string) {
    const existing = get<T>(key);
    const [current, setCurrent] = useState<T | undefined>(existing);

    useEffect(() => {
        const storedValue = get<T>(key)
        if (!isEqual(storedValue,current)) setCurrent(storedValue);

        const handleStoreChange = (event: any) => {
            if (event.detail.key === key) {
                setCurrent(get<T>(key))
            }
        }

        window.addEventListener('localStorageEvent', handleStoreChange);

        return () => {
            window.removeEventListener('localStorageEvent', handleStoreChange)
        }
    }, [key]);
    return current;
}

/**
 * Watches local storage for entries matching the provided partial key.  Useful for when
 * you want to track new or removed entries
 * @param partialKey 
 * @returns 
 */
export function useStoredItemKeys(partialKey: string) {
    const [keys, setKeys] = useState<string[]>(Object.keys(localStorage).filter(k=>k.includes(partialKey)));

    useEffect(() => {
        const storedKeys = Object.keys(localStorage).filter(k=>k.includes(partialKey));
        if (!isEqual(storedKeys, keys)) setKeys(storedKeys);

        const handleStoreChange = (event: any) => {
            if (event.detail.key?.includes(partialKey)) {
                const matchingKeys = Object.keys(localStorage).filter(key => key.includes(partialKey));
                if (matchingKeys.length !== keys.length) {
                    setKeys(matchingKeys);
                }
            }
        }

        window.addEventListener('localStorageEvent', handleStoreChange);

        return () => {
            window.removeEventListener('localStorageEvent', handleStoreChange)
        }
    }, [partialKey]);

    return keys;
}