export async function wrappedHttp<T>(endpoint: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET', token: string, body?: unknown): Promise<T | undefined> {
    try {
        const httpResult = await fetch(endpoint, {
            method,
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: body ? JSON.stringify(body) : undefined
        })
        if (!httpResult.ok) {
            console.error('Non-success status code in API response', await httpResult.text());
            throw new Error();
        }
        const jsonResult = await httpResult.json();
        return jsonResult as T;

    } catch (e) {
        console.error(`Error attempting to call ${endpoint}`, e);
        return undefined;
    }
}