import React from "react"

export const RemainingSessionsIndicator = () => {
    return (
        <div className="flex min-h-10 rounded overflow-hidden">
            <div className="bg-deepBlue flex justify-center items-center p-3">
                <span className="text-white font-bold">Large Package</span>
            </div>
            <div className="bg-slate-200 min-w-10 flex justify-center items-center">
                <span className="font-bold">2</span>
            </div>
        </div>
    )
}