import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { useLoggedInUser } from "./LoggedInUserProvider";
import { wrappedHttp } from "../services/wrappedHttp";
import { Partnership } from "../models/partnership";
import { Config } from "../App";
import { useAuth0 } from "@auth0/auth0-react";
import { set } from '../storeUtils';

export interface IPartnershipProvider {
}
export const PartnershipContext = createContext<IPartnershipProvider>({ });

type ProviderProps = {
    children: ReactNode
}

export const PartnershipProvider = ({ children }: ProviderProps) => {
    // const apiUrl: string = `${Config.API_URL}/partnerships`;
    const { me } = useLoggedInUser();
    const { getAccessTokenSilently } = useAuth0();

    /**
     * Calls the backend to get the logged in user's partnerships
     */
    const getMyPartnerships = useCallback(async (): Promise<{ [id: string]: Partnership }> => {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: Config.API_AUDIENCE
            }
        });
        const result = await wrappedHttp<{ [id: string]: Partnership }>(`${Config.API_URL}/users/me/partnership`, 'GET', token);
        return result ?? {};
    }, [me?.id]);

    const onLoad = useCallback(() => {
        getMyPartnerships().then((result) => {
            Object.values(result).forEach(p => {
                set(`partnership.${p.id}`, p);
            })
        })
    }, [getMyPartnerships]);

    useEffect(() => {
        if (!me) return;
        onLoad();
    }, [me?.id])

    return (
        <PartnershipContext.Provider value={{ }}>
            {children}
        </PartnershipContext.Provider>
    )
}

export const usePartnershipProvider = () => {
    return useContext(PartnershipContext);
}