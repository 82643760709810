import { FaRegTrashCan } from "react-icons/fa6";
import { CardHeader } from "./CardHeader";
import { Card } from "./Card";
import { useUser } from "../providers/UserProvider";
import { useImageUrl } from "../providers/FileProvider";
import { AddButton } from './AddButton';
import { useStoredItem, useStoredItemKeys } from '../providers/useStoredItem';
import { Partnership } from "../models/partnership";
import { Avatar } from './Avatar';
import { useNavigate } from "react-router-dom";
import { initials } from '../styleUtils'

export const ClientList = (props: { onClientClick?: (clientId: string) => void }) => {
    const partnershipKeys = useStoredItemKeys('partnership');
    const navigate = useNavigate();

    return (
        <Card>
            <CardHeader text="Clients">
                <AddButton onClick={() => navigate('/add-client')} />
            </CardHeader>
            {partnershipKeys.map((pKey) =>
                <ClientRow onClientClick={props.onClientClick} key={pKey} partnershipStorageKey={pKey} />
            )}
        </Card>
    )
}

export const ClientRow = (props: { partnershipStorageKey: string, onClientClick?: (clientId: string) => void }) => {
    const partnership = useStoredItem<Partnership>(props.partnershipStorageKey);
    const clientUser = useUser(partnership?.clientUserId);

    return (
        <>
            {clientUser ? <div
                onClick={() => props.onClientClick ? props.onClientClick(clientUser.id) : null}
                className="px-1 flex gap-3 py-2 justify-between hover:bg-deepBlue hover:text-white rounded hover:cursor-pointer">
                <div className="flex gap-2 items-center">
                    <Avatar size={40} imageId={clientUser?.profilePhotoId} alt={initials(clientUser)} />
                    <span>{clientUser.firstName}</span>
                    <span className="font-bold">{clientUser.lastName}</span>
                </div>
            </div> : null}
        </>
    )
}