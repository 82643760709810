import { createContext, ReactNode, useCallback, useContext, useState } from "react";
import { Config } from "../App";
import { NewUserForm, User } from "../models/user";
import { wrappedHttp } from "../services/wrappedHttp";
import { useAuth0 } from "@auth0/auth0-react";
import { get, set } from "../storeUtils";
import { useStoredItem } from "./useStoredItem";

export interface IUserContext {
    getUser: (userId: string | undefined) => Promise<User | undefined>
    addNewClient: (newUser: NewUserForm) => Promise<User | undefined>
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

type ProviderProps = {
    children: ReactNode
}

export const UserProvider = ({ children }: ProviderProps) => {
    const apiUrl = `${Config.API_URL}/users`;
    const { getAccessTokenSilently } = useAuth0();

    const getUser = useCallback(async (userId: string | undefined) => {
        if (!userId) return;

        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: Config.API_AUDIENCE
            }
        });

        const result = await wrappedHttp<User>(`${apiUrl}/${userId}`, 'GET', token);
        if (result) {
            set(`user.${userId}`, result);
        }

        return result;
    }, [getAccessTokenSilently]);

    const addNewClient = useCallback(async (newUser: NewUserForm) => {
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: Config.API_AUDIENCE
            }
        });
        const result = await wrappedHttp<User>(`${apiUrl}`, 'POST', token, newUser);
        if (result) set(`user.${result.id}`, result);
        return result;
    }, [getAccessTokenSilently]);

    return (
        <UserContext.Provider value={{ getUser, addNewClient }}>
            {children}
        </UserContext.Provider>
    )
}

export const useUserProvider = () => {
    return useContext(UserContext);
}

export const useUser = (userId: string | undefined) => {
    const { getUser } = useUserProvider();

    if (userId && !get<User>(`user.${userId}`)) {
        getUser(userId);
    }
    return useStoredItem<User>(`user.${userId}`);
}