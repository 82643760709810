import { Formik } from "formik"
import { Card } from "../components/Card"
import { CardHeader } from "../components/CardHeader"
import { Button } from "../components/Button"
import { useAuth0 } from '@auth0/auth0-react'
import { Config } from "../App"
export const LoginScreen = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <div className="flex w-full justify-center">
            <div className="w-1/2">
                <h1 className="text-5xl text-deepBlue font-bold">Welcome to</h1>
                <h1 className="text-5xl text-deepBlue font-bold">My WellChat</h1>
                <p className="text-xl text-deepBlue pt-3">Make nutrition about you.</p>
                <div className="flex flex-col w-1/2 md:w-1/4">
                    <Button onClick={()=>loginWithRedirect({
                            authorizationParams:{
                                redirect_uri: `${Config.URL}/home`,
                                connection: 'email',
                                audience:Config.API_AUDIENCE
                            }
                        })} className={'bg-deepBlue text-white mt-8 px-8'}>Let's Go!</Button>
                        
                    <Button onClick={()=>loginWithRedirect({
                            authorizationParams:{
                                redirect_uri: `${Config.URL}/home`,
                                connection: 'Username-Password-Authentication',
                                audience:Config.API_AUDIENCE
                            }
                        })} className={'text-deepBlue mt-1 font-medium'}>or log in with password</Button>
                </div>
                {/* <Card>
                    <CardHeader text="Login" />
                    <h2 className="font-bold mt-8">Welcome</h2>
                    <span>Click below to log in</span>
                    <Button onClick={()=>loginWithRedirect({
                        authorizationParams:{
                            connection: 'email',
                            audience:Config.API_AUDIENCE
                        }
                    })} className={'bg-deepBlue text-white mt-8'}>Let's Go!</Button>
                </Card> */}
            </div>
        </div>
    )
}