export const set = (key: string, object: any) => {
    localStorage.setItem(key, JSON.stringify(object));
    emitStorageEvent({key, val: object})
}

export const get = <T>(key: string) => {
    const val = localStorage.getItem(key);
    if (!val) return undefined;
    return JSON.parse(val) as T;
}

const emitStorageEvent = (event:{key:string, val: any}) => {
    window.dispatchEvent(new CustomEvent('localStorageEvent', {
        detail: event,
        bubbles: true,
        cancelable: true
      }));
}