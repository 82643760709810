import { User } from "./models/user";

export const colors = {
  green: '#6C9675',
  offWhite: '#fdf9f1',
  blue: '#578290',
  orange: '#BD6B57',
  red: '#FF5A5F',
  paleBlue: '#D9F0FF',
  black: '#353535',
  deepBlue: '#2B3B65',
  shallowBlue: '#64749D',
  gray: 'gray',
  taupe: '#F7F9F0',
  white: 'white',
};

export const initials = (user: User | undefined) => {
  if (!user) return '?';
  const firstInitial = user.firstName?.charAt(0);
  const lastInitial = user.lastName?.charAt(0);

  return (firstInitial ?? '') + (lastInitial ?? '');
}
