import { ClientList } from "../components/ClientList"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { ClientDetail } from '../components/ClientDetail';
import { useState } from "react";
import { FaUserSecret } from "react-icons/fa6";
import { colors } from "../styleUtils";
import { Card } from "../components/Card";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import { withAuth } from "./withAuth";

export const ClientsScreen = withAuth(() => {
    const navigate = useNavigate();
    const url = useLocation();
    const userRoute = url.pathname.split('/').filter(x=>x).pop();

    return (
        <div className="flex-1 flex sm:flex-row flex-col gap-5">
            <div className="sm:w-1/5">
                <ClientList onClientClick={(clientId) => navigate(`/clients/${clientId}`)} />
            </div>
            <div className="sm:w-4/5">
                {userRoute === 'clients' ? <NoClientSelected/> : null}
                {userRoute !== 'clients' ? <ClientDetail clientUserId={userRoute === 'clients' ? undefined : userRoute} /> : null}
            </div>
        </div>
    )
});

const NoClientSelected = () => {
    return (
        <Card>
            <div className="flex w-full justify-center">
                <FaUserSecret size={30} color={colors.deepBlue} />
            </div>
            <div className="flex w-full justify-center">
                No client selected
            </div>
        </Card>
    )
}