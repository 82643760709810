import { withAuthenticationRequired } from "@auth0/auth0-react"
import React from "react";
import { Card } from "../components/Card";
import { CardHeader } from "../components/CardHeader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from 'yup';
import { Button } from "../components/Button";
import { useUserProvider } from "../providers/UserProvider";
import { NewUserForm } from "../models/user";
import { useNavigate } from "react-router-dom";
import { withAuth } from "./withAuth";


export const AddClientScreen = withAuth(() => {
    const navigate = useNavigate();
    const {addNewClient} = useUserProvider();

    const validationSchema = Yup.object({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        emailAddress: Yup.string().email('Invalid email address').required('Email is required'),
        phoneNumber: Yup.string().notRequired()
    });


    const submitForm = async (values: NewUserForm) =>{
        const newClient = await addNewClient({
            firstName: values.firstName,
            lastName: values.lastName,
            emailAddress: values.emailAddress,
            phoneNumber: values.phoneNumber
        });
        navigate(newClient ? `/clients/${newClient.id}` : `/clients`);
    }

    return (
        <div className="flex-1">
            <Card>
                <CardHeader text={"Add a new client"}></CardHeader>
                <div className="flex gap-5">
                    <div className="max-w-screen-sm flex-1 mt-5">
                        <Formik
                            initialValues={{ firstName: '', lastName: '', emailAddress: '', phoneNumber: undefined }}
                            validationSchema={validationSchema}
                            validateOnChange
                            onSubmit={submitForm}
                        >
                            {({ submitForm, isSubmitting }) => (
                                <Form>
                                    <div className="mb-3">
                                        <TextInput fieldName="firstName" label="First Name" placeholder="Jane" required />
                                    </div>
                                    <div className="mb-3">
                                        <TextInput fieldName="lastName" label="Last Name" placeholder="Doe" required />
                                    </div>
                                    <div className="mb-3">
                                        <TextInput fieldName="emailAddress" label="Email Address" placeholder="jane@email.com" required type="email" />
                                    </div>
                                    <div className="mb-3">
                                        <TextInput fieldName="phoneNumber" label="Phone Number" placeholder="(555) 123-4567" type="phone" />
                                    </div>

                                    <Button disabled={isSubmitting} onClick={submitForm} className="bg-deepBlue text-white w-20">Save</Button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </Card>
        </div>
    )
});

const TextInput = (props: { fieldName: string, label: string, placeholder: string, type?: string, required?: boolean }) => {
    return (
        <div className="flex flex-col">
            <label className="font-bold ml-1" htmlFor={props.fieldName}>{props.label} {props.required ? '*' : ''}</label>
            <Field className='bg-slate-100 rounded h-9 p-2 mt-1' type={props.type ?? 'string'} placeholder={props.placeholder} name={props.fieldName} />
            <ErrorMessage className='text-red' name={props.fieldName} component="div" />
        </div>
    )
}