import React, { ReactNode } from 'react';
type CardProps = {
    children: ReactNode;
    
}
export const Card = ({children}:CardProps) =>{
    return(
        <div className="p-5 bg-white w-full flex flex-col rounded shadow-md overflow-scroll">
            {children}
        </div>
    )
}