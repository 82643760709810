import { ReactNode } from "react";

export type ButtonProps = {
    className?: React.HTMLAttributes<HTMLButtonElement>['className'];
    onClick: ()=>void;
    children: ReactNode;
    disabled?:boolean
}

export const Button = (props:ButtonProps) => {
    return (
        <button disabled={props.disabled} onClick={props.onClick} type='button' className={"rounded p-2 font-bold whitespace-nowrap " + props.className}>{props.children}</button>
    )
}