import React, { ComponentType, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const withAuth = (WrappedComponent: ComponentType<any>) => {
    return (props: any) => {
        const { isAuthenticated, isLoading } = useAuth0();
        const navigate = useNavigate();
        useEffect(() => {
            if (!isLoading && !isAuthenticated) navigate('/login');
        }, [isAuthenticated, isLoading]);

        return <WrappedComponent {...props} />;
    };
}