import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { User } from '../models/user';
import { wrappedHttp } from "../services/wrappedHttp";
import { Config } from "../App";
import { useAuth0 } from '@auth0/auth0-react';

export interface ILoggedInUserContext {
    me: User | undefined,
    isLoading: boolean,
    refresh: () => Promise<void> | void
}
export const LoggedInUserContext = createContext<ILoggedInUserContext>({ me: undefined, isLoading: true, refresh: () => { } });

type ProviderProps = {
    children: ReactNode
}

export const LoggedInUserProvider = ({ children }: ProviderProps) => {
    const apiUrl: string = `${Config.API_URL}/users`;
    const [me, setMe] = useState<User>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { getAccessTokenSilently, user } = useAuth0();

    const refresh = useCallback(async () => {
        console.debug('refreshing user')
        const token = await getAccessTokenSilently({
            authorizationParams: {
                audience: Config.API_AUDIENCE
            }
        });
        const loggedInUser = await wrappedHttp<User>(`${apiUrl}/me`, 'GET', token);
        if (loggedInUser) setMe(loggedInUser);
    }, [getAccessTokenSilently]);

    useEffect(() => {
        if (!user) return;
        refresh().then(() => setIsLoading(false), () => console.error('error refreshing logged in user'));
    }, [user?.sub]);

    return (
        <LoggedInUserContext.Provider value={{ me, isLoading, refresh }}>
            {children}
        </LoggedInUserContext.Provider>
    )
}

export const useLoggedInUser = () => {
    return useContext(LoggedInUserContext);
}